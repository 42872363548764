.footer {
  background-color: #2B6EB5; /* Background color of the footer */
  color: whitesmoke; /* Text color of the footer */
  padding: 20px; /* Padding around the content */
}

.mainfooter {
  display: flex;
  justify-content: space-between;
}

.othertext {
  margin-top: 10px;
}

.othertext span {
  margin-right: 10px;
  color: #fff8f8;
}
